<template>
  <v-app>
    <v-banner
        class="lavBanner" 
        single-line
        height="400"
    >
      <v-img
          src="https://h5.ophyer.cn/official_website/banner/IPCase-banner.png"
          height="100%"
          class="d-flex align-center"
        >
        <template v-slot:placeholder>
          <v-row class="fill-height ma-0" align="center" justify="center">
            <v-progress-circular
              indeterminate
              size="80"
              color="red lighten-5"
            ></v-progress-circular>
          </v-row>
        </template>
        <v-container color="transparent" class="d-flex justify-center">
          <v-card color="transparent" flat width="80%">
            <div class="d-flex flex-no-wrap justify-center" style="color: #fff">
              <div>
                <v-card-title class="white--text font-size-banner-title font-weight-bold px-0">
                   &nbsp;&nbsp;&nbsp;&nbsp;合作案例
                </v-card-title>
                <v-card-title class="white--text font-size-banner-tip px-0" style="line-height:30px;margin:0 auto;text-align: center;display: block;">
                  IP资源结合行业特点，打造品牌商业亮点
                </v-card-title>
              </div>
            </div>
          </v-card>
        </v-container>
      </v-img>
    </v-banner>
    <v-container class="mt-7" >
        <v-tabs v-model="tab" centered color="#0084ff" class="g-tabs new-tabs-two" >
          <v-tab v-for="(item,index) in data" :key="index">
            <v-card-title class="font-size-16" :style="{'color':tab == index ? '#0084ff!important':''}">{{ item.tabTilte }}</v-card-title>
          </v-tab>
        </v-tabs>
        <div class="new-two">
        <v-tabs-items v-model="tab" >
            <v-tab-item v-for="(item,index) in data" :key="index" class="mt-7" style="margin:0 auto;">
                <v-row v-show="tab === index" style="margin:12px auto;width:1120px">
                    <v-col v-for="(info,infoIndex) in item.tabItem" :key="infoIndex" cols="3">
                        <v-card
                            class="mx-auto item-card "
                            width="240"
                            height="410"
                            @click="goCaseDetail(5,index,infoIndex)"
                        >
                            <v-img
                                width="220"
                                height="340"
                                class="tran-sec"
                                :src="info.coverImg"
                                :aspect-ratio="9/16"
                            >
                            </v-img>
                            <div class="text-box">
                                <v-card-text class="font-size-14 sizeStyle" v-text="info.itemTitle"></v-card-text>
                            </div>
                        </v-card>
                    </v-col>
                </v-row>
            </v-tab-item>
        </v-tabs-items>
        </div>
    </v-container>
  </v-app>
</template>

<script>
import textData from '@/static/textData.js'
import qs from 'qs'
//let _this;
export default {
  name: "IPcase",
  data() {
    return {
        imgLoad: false,
        data: textData.CooperationCase[6].tab,
        levelOneIndex: 0,
        levelTwoIndex: 0,
        tab: null,
        showDialog: false
    }
  },
  mounted(){
     //_this = this;
    // this.bannerLoad = true;
  },
  methods: {

    // goCaseDetail:(item) =>{
    //   let query = {
    //     source: 'qita',
    //     anli: item.self
    //   }
    //   let url = '/CaseDetail?'+qs.stringify(query)
    //   window.open(url, '_blank')
    // },
    goCaseDetail(levelOneIndex, levelTwoIndex, vid) {
      let query = {
        source: 'case',
        levelOneIndex: levelOneIndex,
        levelTwoIndex: levelTwoIndex,
        vid: vid,
      }
      let url = '/CaseDetail?'+qs.stringify(query)
      window.open(url, '_blank')
    },
  }
}
</script>

<style lang="scss" scoped>
.lavBanner {
  ::v-deep .v-banner__wrapper,
  ::v-deep .v-banner__content,
  ::v-deep .v-banner__text {
    padding: 0 !important;
    height: 100%;
  }
}
::v-deep .theme--light.v-tabs-items{
     background: #f5f8fd !important; 
}
::v-deep .new-tabs-two{
  height: 70px !important;
  padding: 0 !important;
}
::v-deep .new-tabs-two .v-tabs-bar__content{
  padding: 0 !important;
}
.item-card {
  margin-bottom: 15px;
  padding: 10px;
  box-shadow: 0px 1px 14px 0px rgb(0 60 255 / 8%) !important;
  border-radius: 8px;
  transition: 0.3s;
  &:hover {
    transform: scale(1.04);
    box-shadow: 0px 10px 20px 0px rgba(0, 60, 255, 0.1) !important;
  }
  &::after {
      content: "";
      background-image: url("./../../assets/images/home/case-icon.png");
      background-size: 100%;
      width: 20px;
      height: 20px;
      position: absolute;
      bottom: 16px;
      right: 10px;
  }
  &:focus{
    display: none;
  }
}
.sizeStyle{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
